import { IThirdPartyComponent } from '../interfaces/ThirdPartyComponent';
import axiosUtils from '../utils/axios';

const getThirdPartyComponents = async (language:string|null): Promise<IThirdPartyComponent> => {
    const result = await axiosUtils.instance.get<IThirdPartyComponent>(`api/3rdpartycomponents?language=${language}`);
    return result.data;
}

export default{
    getThirdPartyComponents
}