import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import thirdPartyComponentsService from "../../services/thirdPartyComponentsService";
import { IThirdPartyComponent } from "../../interfaces/ThirdPartyComponent";
import "../../styles/thirdPartyComponents.css";
import { ComponentLoadingSpinner } from "../../components/ComponentLoadingSpinner";

export const ThirdPartyComponentPage = () => {
    const { t } = useTranslation("general");
    const [thirdPartycomponents, set3rdpartycomponents] = useState<IThirdPartyComponent>({ components: [] });
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [language, setLanguage] = useState<string>("dotnet");

    useEffect(() => {
        thirdPartyComponentsService.getThirdPartyComponents("dotnet")
            .then(components => {
                set3rdpartycomponents(components);
                setShowLoading(false);
            });
    }, [])

    function getComponents(language: string) {
        setShowLoading(true);
        setLanguage(language);
        thirdPartyComponentsService.getThirdPartyComponents(language)
            .then(components => {
                set3rdpartycomponents(components);
                setShowLoading(false);
            });
    }

    return (
        <>
            <div className="ar-page-container container">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title components-title">{t("3rdPartyComponents")}</div>
                    </div>
                    <div className="panel-body">
                        <div>
                            <button onClick={() => getComponents("dotnet")} className={`${language === "dotnet" && 'btn btn-primary' || 'btn'}`} data-testid="get-backend-components">{t("BackendComponents")}</button>
                            <button onClick={() => getComponents("js")} className={`${language === "js" && 'btn btn-primary' || 'btn'}`} data-testid="get-ui-components">{t("UIComponents")}</button>
                        </div>
                        {showLoading && <ComponentLoadingSpinner />}
                        {!showLoading &&
                            <>
                                <div id="thirdPartyComponents" className="fullHeight fullHeight-container fullWidth">
                                    <div className="components-table">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>{t("Name")}</th>
                                                    <th>{t("Version")}</th>
                                                    <th>{t("License")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {thirdPartycomponents.components.map((p, index) => (
                                                    <tr key={index}>
                                                        <td>{p.ComponentName}</td>
                                                        <td>{p.Version}</td>
                                                        <td>{p.LicenseType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}